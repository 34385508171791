
@font-face {
    font-family: 'TwemojiMozilla';
    src: url('TwemojiMozilla.ttf');
}
.sel-field{
    display:inline-block;
    min-width: 150px;
}
.sel-country{
    display:block;
    width:320px;
    margin-bottom: 1em;
    
}

.flag{
    font-family: 'TwemojiMozilla';
    font-size: 1.5em;
    margin-right: 1em;
}

.pass-field{
    display:inline-block;
    padding: 0.5em;
    border-radius: 0.2em;
    border: 1px solid var(--bd-color);
    color:var(--txt-color);
    /*font-weight: bold;*/
    font-size: 1.2em;
    width:300px;
    background-color :var(--bg);
}

.pass-gen{
    position: relative;
    top:-0.5em;
}

.disabled-fieldset{
    background-color: var(--disabled);
    color:#000;
}