.group-time{
    display:flex;
    margin-top:2rem;
    align-content:'center';
}

.time-input {
    width:3rem;
    margin : 0 1rem;
}

.head{
    display: flex;
    padding: 1rem;
    font-size: 1.2rem;
}
.head > span{
    font-weight: bold;
}

fieldset.fld-repeat{
    padding: 1rem 0.5rem;
    background-color: rgb(240,240,240);
    margin-top: 1rem;
}

/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */

input[type=number] {
/* -moz-appearance: textfield; */
  width:3.3rem;
}

.repeat-type {
    width:12rem;
    display:inline-block;
}
/*fieldset span {
    font-size: 1.2rem;
}*/

.repeat-type, input[type=number], fieldset.fld-repeat span {
    margin-right: 1rem;
}

.error-tbl{
    width: 95%;
    margin-top:1rem;
    font-size:0.9rem;
}

.error-tbl th {
    
    background-color: #f0f0f0;
    color:#404040;
}
.error-tbl td{
    color:#555555;
}