ul{
    list-style-type: none;
    margin:0;
    padding: 0;
}
ul li{
    margin: 0;
    padding: 0;
}

.lbl-clear{
    color:royalblue;
    cursor: pointer;
    margin:0.5em auto 1em 1em;
    display:inline-block;
}
.lbl-clear:hover{
    text-decoration: underline;
    
}

.icons{
    position: relative;
    padding: 0.2em;
    border: 2px solid transparent;
}
.icon-sm{
    font-size: 1.2em;
    margin: 0 0.1em;
}
.icons:hover{
    border: 2px solid var(--dec);
    border-radius: 0.2em;
}
.icons:active{
   
    opacity: 0.75;
}