.context-menu{
    position:absolute;
    box-sizing:border-box;
    top:0;
    left:0;
    width:150px;
    background:#000;
    color:#fff;
    padding:1em;
     z-index:5;
}

.context-menu-link{
    color:#fff;
    text-decoration: none;
}
.context-menu-link:hover{
    text-decoration: underline;
}