.login-container{
    margin:0 auto;
    border:1px solid #000;
    width:50%;
    display:grid;
    justify-content: center;
    align-content: center;
    background-color: #fff;
    min-width: 350px;
    max-width: 600px;
}

.login-btn{
    background-color:var(--dec);
    color:#fff;
    margin:0.25em  0 1em 0;
    font-size:1.5em;
    max-width: 320px;
}
.ret-link{
    background-color: transparent;
    border: none;
    cursor:pointer;
    color:var(--dec);
    font-size:1.2em;
    max-width: 320px;
    margin-bottom: 1em;
    
}
.ret-link:hover, .link:hover{
    text-decoration: underline;
}

.login-message{
    /*border: 1px solid red;*/
    
    min-height: 2em;
    margin-top:0.5em;
    padding-top: 1em;
    max-width: 350px;
    padding-left: 0.75em;
}
.vf-code{
    letter-spacing: 0.5em;
    font-weight: bold;
}

.err{
    color :#d8000c;
    background-color:#ffbaba;
}
.suc{
    color: #270;
    background-color:#dff2bf;
}
.help{
    font-size:1.2em;
    width:100%;
    min-width: 320px;
    max-width: 500px;
}
img.qrcode{
    margin:1.5em auto;
}

.link{
    display: inline;
    padding: 0;
    background-color: transparent;
    border: none;
    cursor:pointer;
    color:var(--dec);
    font-size:1em;
}
.icons, .icons-app{
    position: relative;
    padding: 0.5em;
    border: 2px solid transparent;
    color: var(--dec);
    font-size: 1.2em;
    bottom: -0.5em;
    
}
.icons-app{
    bottom:unset;
}
    

.icons:hover, .icons-app:hover{
    border: 2px solid var(--dec);
    border-radius: 0.2em;
}
.icons:active, .icons-app:active{
    bottom: -0.58em;
    opacity: 1;
}
.icons-app:active{
    bottom: -0.1rem;
}

