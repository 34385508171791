
.container{
   
    /*border:1px solid #000;*/
    display:grid;
    grid-template: repeat(3, auto) / repeat(12, 1fr) ;
    
}
/* .limit{
    height:calc(100vh - 3.8rem);
    overflow:clip;
} */

.header{
    grid-column: 3 / -1;
    margin-bottom: 1em;
}
h2{
    font-size: 1.3em;
    margin-left: 1.5em;
}

.side-bar{
    list-style-type: none;
    margin:0;
    padding:0;
    grid-column: 1 / 3;
    
}
.side-bar > li{
    margin: 0;
    padding:1.5em 0.5em;
    cursor:pointer;
    border-bottom:1px solid #dadada;
    border-left: 5px solid #f0f0f0;
}
.side-bar > li:hover{
    text-decoration: underline;
}
.side-bar > li.active-section{
    background-color: #fff;
    border-left: 5px solid var(--dec);
}

.content{
    grid-column: 3 / -1;
    background-color: #fff;
    padding-left: 1em;
}
.action-content {
    background-color: #fff;
}
.section, .section-action{
    display:none;
    padding: 1em;
    text-align: left;
}
.current, .section-action{
    display: flex;
    justify-content:space-between;
    margin-bottom: 2em;
    flex-wrap: wrap;
    min-height: 350px ;
}

.left-side, .right-side, .both-sides{
    position: relative;
    width: 50%;
}
.both-sides{
    width: 100%;
}
.left-mid-side, .right-mid-side{
    position: relative;
    width: 33%;
}

.left-side::after, .left-mid-side::after{
   position: absolute;
   content:'';
   height:100%;
   top:0;
   right:2em;
   width:1px;
   background-color:#e0e0e0;
   display:block;
}

label{
    display:block;
    font-weight: bold;
    margin-top:1.5em;
    margin-bottom: 0.5em;
    /*max-width: 350px;*/
}

label.lbl-inline{
    display:unset;
    margin-right: 2em;
    font-weight: normal;
    cursor: pointer;
}
label.lbl-klimakio{
    display:unset;
    margin-right: 3em;
}

input[type=text], input[type=password], input[type=number], .date-input, .wh-time_input, textarea{
    padding: 0.5em;
    border-radius: 0.2em;
    border: 1px solid var(--bd-color);
    color:var(--txt-color);
    font-size: 1.2em;
    width:300px;
}
input[type=text].lg{
    width: 90%;
    box-sizing: border-box;
}
textarea.profile{
    width: 90%;
    height: 5rem;
    box-sizing: border-box;
}
textarea.large-text{
    width: 90%;
    height: 300px;
    box-sizing: border-box;
}

.wh-time-input{
    font-size: 1.2rem;
    width:100px;
}
.wh-time-input:disabled{
    background-color: #ccc;
}

input[type=text]:focus, input[type=password]:focus, .date-input:focus, .wh-time-input:focus, input[type=number]:focus{
    outline: 3px solid var(--bd-color);
}
textarea:focus{
    outline: 1px solid var(--bd-color);
}
input[type=text].details{
    border-radius: 0;
    border: none;
    border-bottom: 1px solid var(--dec);
    margin: 0 0.25em;
    font-size: 0.8em;
}


input[type=text]:read-only{
    border: none;
} 
input[type=text]:read-only:focus{
    outline: none;
} 

input[type=text]:disabled, .date-input:disabled, textarea:disabled{
    outline: none;
    background-color: var(--bg);
} 

input[type=text].short{
    width:150px;
}
input[type=text].details:focus{
    outline:none;
}
input[type=text].details::placeholder{
    font-size:0.8em;
}

.footer{
    grid-column: 1 / -1;
}

button.react-datepicker__close-icon:after{
    background-color: var(--dec);
}

.origin-flag{
    font-family: 'TwemojiMozilla';
    font-size: 1.5em;
    margin-left: 0.5em;
    padding-top: 1em;
}

.ch-pass-btn{
    border:1px solid var(--dec);
    color:var(--dec);
    cursor:pointer;
    padding:0.7em;
    margin-left:0.5em;
   
}
.ch-pass-btn:hover{
    text-decoration: underline;
    
}
.ch-pass-btn:active{
    opacity: 0.6;
    
}

fieldset {
    margin-top: 5em;
    margin-left: -1em;
    padding-top: 0;
    width:90%;
    border: 1px solid  var(--action-dec);
}
legend{
    padding:0 0.5em;
    margin-left: -0.5em;
    font-weight: bold;
}

.rem-area{
    padding:0 1em;
    margin-bottom: 0.5em;
}
#action_rem{
    height:100px;
}
/*
#doctor_amka:not(:focus) + .icons-warn {
    position: relative;
    padding: 0.5em;
    border: 2px solid transparent;
    color: orange;
    font-size: 1.2em;
    bottom: -0.5em;
}

.no-visible, #doctor_amka:focus + .icons-warn {
    visibility: hidden;
    padding: 0.5em;
    font-size: 1.2em;
    border: 2px solid transparent;
}
*/

.icons-warn, .no-visible {
    position: relative;
    padding: 0.5em;
    border: 2px solid transparent;
    color: orange;
    font-size: 1.2em;
    bottom: -0.5em;
}
.no-visible{
    visibility: hidden;
}
.explain{
    box-sizing: border-box;
    color:var(--title-bg);
    font-size: 0.8rem;
    font-weight: 500;
    font-style: italic;
    max-width: 95%;
}

sup{
    font-weight: normal;
    font-style: italic;
    font-size: 0.75em;
    color: #333;
}
.text-container{
    box-sizing: border-box;
    width:90%;
    border:1px solid var(--bd-color);
    border-radius: 0.2rem;
    padding:0.5rem;
    font-size: 1rem;
    text-overflow: ellipsis;
    line-height: 1.25rem;
    text-align: justify;
    color:dimgray;
    font-style: italic;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height:4.2rem;
    background-color: #f7f7f7;
}

textarea.edit-content{
    background-color:#fff;
    flex:1;
    box-sizing: border-box;
    color:var(--txt-color);
    line-height: 1.8rem;
    font-size: 1.2rem;
    font-style: normal;
    text-align: justify;
    padding:0.5rem 1.5rem;
}
