p {
    padding: 3em 1em;
    font-size: 1.2em;
}
.switch-period{
    border: none;
    /* outline:1px solid var(--dec);  */
    color: var(--dec);
    cursor: pointer;
    padding: 0.7em;
    margin-bottom: 1rem;
    background-color: transparent;
    display: block;
    font-size: 1rem;
    padding:0.75rem 0;
    max-width: 350px;
}
.switch-period:hover {
    text-decoration: underline;
}
.download{
    background-color: var(--dec);
    color: #fff;
    margin: 0 0 0.5em 0;
    font-size: 1.5em;
    padding: 0.35em 4em;
    max-width: 320px;
}

.date-sep{
    margin-bottom: 2rem;
}

.lbl-switch-period{
    display: inline;
    margin-right: 2rem;
    font-weight: normal;
    cursor: pointer;
}
.lbl-switch-period:hover{
    text-decoration: underline;
}