
.action-profile-container{
    display:grid;
    grid-template: repeat(6, auto) / repeat(12, 1fr) ;
    border:1px solid var(--action-dec);
    margin:1em 2em 1em 1em;
    column-gap: 5px;
    row-gap: 0.75em;
    border-radius: 8px;
    padding: 0.75em;
    justify-content: space-evenly;
}

.current-action{
   /* border-color: var(--bd-color);*/
    border-width: 5px;
    /*margin-left: 0;*/
}

.blank-cell{
   grid-column: 2/12; 
   grid-row:1/2;
}

.contact-name{
    grid-column: 2/5; 
    grid-row:1/2;
    color:dimgray;
    font-size: 1.1em;
}

.contact-info {
    grid-row:1/2;
    grid-column: 5/8; 
    font-size: 1em;
    color:dimgray;
}

.contact-amka{
    grid-row:1/2;
    grid-column: 8/11;
}
.contact-folder{
    grid-row:1/2;
    grid-column: 11/12;
}
.contact-folder span{
    font-size: 0.8em;
    font-weight: bold;
    margin-right: 0.5em;
}
.action-date{
    grid-column:1/2 ;
    grid-row:  1/-1;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background-color: var(--bg);
    padding: 0 0.75em;
    /*border-radius: 8px 0 0 8px;*/
}
.action-place, .action-m-unit, .action-visit-type, .action-klimakio{
    color: #505080;
}
.action-place {
    grid-column: 2/8;
    grid-row:  2/3;
    font-weight: bold;
}
/* 
.action-m-unit{
    grid-column: 5/8;
    grid-row:  2/3;
} */
.action-visit-type{
    grid-column: 8/12;
    grid-row:  2/3;
}
/* .action-klimakio{
    grid-column: 11/12;
    grid-row:  2/3;
} */
.action-tool{
    grid-column: 12/-1;
    grid-row: 1/-1;
    justify-self: center;
    align-self: center;
    display: flex;
    flex-direction: column;
}
.action-tool  svg{
    font-size: 1.5em;
    color:var(--dec);
    align-self: center;
    padding: 0.5em 0;
}
.action-tool div{
    font-size: 0.8em;
    color:var(--dec);
}
.action-tool  svg:hover{
    opacity:0.5;
    cursor: pointer;
}

.action-type{
    grid-column: 2/12;
    grid-row:3/4;
}

.a-date{
    text-align: center;
    font-weight: bold;
    color:var(--txt-color);
    margin-bottom: 5px;
}
.is-new{
    text-align: center;
    font-size: 0.8em;
    color:var(--bd-color);
}
.new-y{
    color:var(--dec);
}
.a-diagnosis{
    grid-row: 4/5;
    grid-column:2/12;
    color: dimgray;
    font-size: 0.9em;
}
.a-diagnosis ul{
    list-style-type:lower-roman;
}
.a-diagnosis ul li{
    margin-left: 1em;
}
.d-code{
    font-weight: bold;
    margin-right: 0.5em;
}
.a-medication{
    grid-row: 5/6;
    grid-column:2/12;
    font-size: 0.9rem;
    color:var(--txt-color);
}
.a-participants{
    grid-row: 6/7;
    grid-column:2/12;
    text-align: end;
    font-size: 0.8em;
    font-style: italic;
}


.action-list-header {
    display:flex;
    align-items: center;
    /*justify-content: space-between;*/
    margin:2em 2em 2em 1em;
}
/*
.action-list-header > div{
    margin-right: 2em;
}
*/
.icon-add-action{
    position: relative;
    padding: 0.5em;
    border: 2px solid transparent;
    color: var(--dec);
    font-size: 1.5rem;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    margin-left: 2em;
}
.icon-add-action:hover{
    border: 1px solid var(--dec);
    
}/*
.icon-add-action:active{
    top:1px;
    opacity: 0.7;
}

.action-list-header .add-info{
    display: flex;
    align-items: center;
   color: var(--dec);
   font-size: 0.8em;
   cursor: pointer;
   margin-left: 4em;
}*/
.action-title{
    padding-left: 1em;
}
.action-title span{
    color:var(--dec);
}
/*
.tpn{
    background-color: transparent;
    border:none;
    cursor: pointer;
}
.tpn > svg{
    pointer-events: none;
}
*/

