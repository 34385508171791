.loader{
    position:fixed;
    display:flex;
    justify-content: center;
    align-items: center;
    height:70px;
    width:200px;
    top:calc((100vh - 70px)/2);
    left :calc((100vw - 200px)/2);
    border:1px solid var(--dec);
    color:#fff;
    font-size: 2em;
    background-color: var(--dec);
    z-index: 10;
    border-radius: 5px;
}
.icon-loader{
    
    font-size: 1.1em;
    margin-left:0.5em;

}