:root{
    --hrsw:70px;
    --dw:150px;
    --cw:calc(var(--dw)*7);
}
.schdl-container{
    position: relative;
    height:100%;
    /*border:1px solid #000;*/
    margin:0 auto;
    padding:0;
    width:100%;
    max-width: 1500px;
    
}

.schdl-flexcol-container{
    display: flex;
    flex-direction: column;
    /*border:1px solid #0000ff;*/
}

.schdl-flexrow{
    display: flex;
    
}
.head-days{
    position:sticky;
    top:10.3rem;
    z-index: 3;
    background-color: #e0e0e0;
    overflow: hidden;
}

.days-container{
    width:13.3%;/*var(--dw);*/
    height:100px;
    border-left:1px solid #b0b0b0;
    border-bottom:1px solid #808080;
    border-top:1px solid #808080;
    box-sizing: border-box;
    min-width: 13.3%;/*var(--dw);*/
}
.days-container:first-child{
    width:6.91%;/*var(--hrsw);*/
    min-width:6.91%;/* var(--hrsw);*/
}

.days-container:last-child{
    border-right:1px solid #b0b0b0;
}


.flexhours{
    width: 6.91%; /*var(--hrsw);*/
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: var(--hrsw);
    border-top: 1px solid #808080;
}

.flexhours > div{
    width:100%;
    height:50px;
    border-bottom:1px solid #808080;
    border-left:1px solid #808080;
    box-sizing: border-box;
    text-align: right;
    padding-top: 5px;
    padding-right: 5px;
}
.flexhours > div:nth-child(odd){
    border-bottom:1px dashed #808080;
}
.flexhours > div.nb{
    border-bottom:none;
}





.days-hours{
    position: relative;
    z-index:0;
    box-sizing: border-box;
    width:93.1%;/*var(--cw);*/
    /*min-width:var(--cw);*/

    border-left:1px solid #808080;
}

.flexdayshours-container{
    display: flex;
    width:100%;
    box-sizing: border-box;
    border-top:1px solid #808080;
}
.flexdayshours{
    display:flex;
    flex-direction: column;
    width:14.28%;/*var(--dw);*/
    max-width: 14.28%;/*var(--dw);*/
    box-sizing: border-box;
    
    
}
.flexdayshours > div{
    width:100%;/*var(--dw);*/
    height: 100px;
    border-bottom: 1px solid #808080;
    border-right: 1px solid #808080;
    box-sizing: border-box;
    margin: auto;
}


.appointment{
    position: absolute;
    /*width:calc(var(--dw) *0.9);*/
    width:14.28%; /*calc(14.28% * 0.5);*/
    border-radius: 0.4rem;
    top:0;
    z-index: 1;
    box-sizing: border-box;
    opacity: 0.9;
    background-color: #b7c9d3;
    border: 1px solid #6399ae;
    overflow: hidden;
    padding-left: 0.25rem;
}  

.appointment .app-time{
    font-weight: bold;
    font-size: 0.8rem;
}
.appointment .app-room{
    color:#00205b;/*#84bd00;/*#a51890;/*#00205b;*/
    width: calc(100% - 0.25rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appointment .app-profile{
    width: calc(100% - 0.25rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:0.9rem;
    color: #57595B;
}
.appointment .app-profile > span{
    background-color: #fff;
    color:#000;
    font-size:0.7rem;
    font-weight: bold;
    margin-right:0.25rem;
}

.app-a{
    background-color: lightblue;
    border:1px solid #000080;
}
.app-b{
    background-color:lightgreen;
    border:1px solid #008000;
}
.app-c{
    background-color:pink;
    border:1px solid #800000;
}



ul.schdl-control{
    position: sticky;
    top:4.3em;
    z-index: 4;
    list-style: none;
    margin:0;
    display:flex;
    font-size: 1rem;
    padding: 1rem 0;
    background-color: var(--bg);
    align-items: center;
    height:4rem;
}

ul.schdl-control li {
    padding: 0 0.2rem;
}
.week-days{
    position: relative;
}


.week-days a{
   text-decoration: none;
   color:unset;
}


.week-days-des,.week-days-num, .week-days-month {
    text-align: center;
    padding: 0.2rem 0;
   
}

.today{
    color:var(--dec);
    font-weight: bold;
}

.week-days-des{
    font-size:1rem;
    
}

.week-days-num{
    font-size:2rem;
    font-weight: bold;
    
}
.week-days-month {
    font-size:1rem;
}

.rv-day-counter{
    position: absolute;
    bottom:0.5rem;
    right:0.5rem;
    font-size:  0.75rem;
    font-weight: bold;
    background-color: var(--dec);
    color:#fff;
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.filter-container{
    max-height:4rem;
    overflow-Y:scroll;
}

.filter-container > li{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/***************** appointment class colors ********************/
/*.app-1{
    background-color:#c2ded1;
    border:1px solid #354259;
}
.app-2{
    background-color:#ffc3c3;
    border:1px solid #ff5d5d;
}
.app-3{
    background-color:#c1f4c5;
    border:1px solid #65c18c;
}

.app-4{
    background-color:#e6ddc6;
    border:1px solid #a19882;
}*/



