.list-container{
    /*border: 1px solid #000;*/
    width: 100%;
   
}
fieldset.search{
    margin: 0;
    background-color: #f0f0f0;
    width: unset;
    position: sticky;
    top:2.6rem;
    z-index: 1;
    
}

.search ul {
    list-style-type: none;
    display:flex;
    align-items: center;
    padding: 0;
    position:relative
}
.search ul li {
    margin-right: 1em;
}

fieldset.search label {
    margin-top: 0;
}

fieldset.search input {
    padding: 0.5em ;
    outline: none;
} 

fieldset.search input:focus{
    outline:none;

}

input.sm{
    width:3em;
}

.sel-part, .sel-part-4, .sel-part-5, .sel-part-90{
    margin:0;
    width: 300px;
}
.sel-part-4 {
    width: 400px;
}
.sel-part-5 {
    width: 500px;
}
.sel-part-90{
    width:90%;
}
.footer{
    position: sticky;
    bottom: 0;
    background-color: var(--action-dec) ;
    
    padding:0.5em 2em 0.5em 1em ;
    display:flex;
    justify-content: space-between;
}
.react-select__loading-indicator {
    font-size: unset;
}

.action-list-content{
    background-color: #fff;
    padding-top: 0.25em;
}

