.sm-lbl{
    width:70%;
    line-height: 2em;
}

ul.nosos{
    list-style-type:lower-roman;
    margin-top: 1em;
}
ul.nosos li{
    margin-left: 1em;
    padding: 0.5em 0;
}

.contact-fields{
    display:flex;
    padding: 0.5em 1em 1em 1em;
    font-size: 1.4em;
    gap: 3em;
}

.contact-fields span{
    font-weight: bold;
}