.list-container{
    
    /*border: 1px solid #000;*/
    width: 100%;
}
.search-form{
    border:none;
    padding:1em 0;
    background-color: #f0f0f0;
}
.search-form span{
    padding: 0 0 0 1rem;
    margin: 0.1em 0;
    display: inline-block;
    position: relative;
   
    font-weight: bold;
   
   
}

.search-form ul, .list-comp ul{
    list-style-type: none;
    
    padding:0 0 0 1rem;
    display:flex;
    align-items: center;
    flex-wrap: wrap;
}
.list-comp ul{
    margin: 0;
    padding: 0;
}
.search-form li{
    margin: 0 1em;
}

.search-form ul.no-tbl li{
    margin: 0 0.5em 0 0 ;
}
.search-form  li:last-child{
   
    margin-left: auto;
}
.search-form label{
    margin:0 0 0.2em 0;
    font-weight: normal;
}

.search-form input[type=text]{
    width:auto;
    padding: 0.2em;
    color:var(--menu-bg);
    max-width: 150px;
}
.search-form input[type=text].sm{
    width:5em;
}
.search-form input[type=text]:focus{
    outline: none;
}

 .date-input-search, .date-input-search-app{
    width:8em;
    padding: 0.3em;
    font-size: 1.1em;
    border-radius: 0.2em;
    border: 1px solid var(--bd-color);
    outline: none;
}
.date-input-search-app{
    padding: 0.6rem;
}

.react-datepicker-popper {
    z-index: 5;
}
.search-form  .icons{
    position: relative;
    padding: 0.2em;
    border: 2px solid transparent;
}
.icon-sm{
    font-size: 1.2em;
    margin: 0 0.1em;
}


.icon-account{
    font-size: 0.8em;
    color:var(--dec);
    margin-left: 0.2em;
}
.icon-account-disabled{
    font-size: 0.8em;
    color:#a00000;
    margin-left: 0.2em;
}


.search-form  .icons:hover{
    border: 2px solid var(--dec);
    border-radius: 0.2em;
}
.search-form .icons:active{
   
    opacity: 0.75;
}

.icon-sm-mail, .icon-sm-mail-s{
    padding: 0.2em;
    border: 2px solid transparent;
    color:var(--dec);
}
.icon-sm-mail-off{
    padding: 0.2em;
    color:#ffbaba;
}

.icon-sm-mail:hover{
    border: 2px solid var(--dec);
    border-radius: 0.2em;
    cursor: pointer;
    /*position: relative;*/
}
.icon-sm-mail:active{
    /*top:1px;
    left:1px;*/
    opacity: 0.75;
}

.list-comp{
    width:100%;
    border-collapse: collapse;
}

.list-comp thead {
    text-align: left;
    background-color:#e0e0e0;
    position: sticky;
    top: 3.9rem;
    z-index: 1;
    
}
.list-comp tbody{
    background-color: #fff;
    overflow: hidden;
}

.list-comp tfoot{
    background-color: #e0e0e0;
    position:sticky;
    bottom:0;
}
.list-comp tbody tr {
    border-bottom: 1px solid #e7e7e7;
}
.list-comp tbody tr.last-visit{
    border: 0.2em solid #e7e7e7;
    /*border-right: 3px solid #c1a4fc;*/
    
}

/*.list-comp tbody tr :nth-of-type(1) {*/
.list-comp tbody tr:nth-child(even) {
    background-color: #f8f8f8;
}
.list-comp tbody tr:hover {
    background-color: #f5f5fa;
}


.list-comp  td, .list-comp thead th {
    padding:1em 0.5em;
}
.list-comp thead th, .list-comp tbody td {
    padding-left: 1em;
}

.list-comp thead td {
    padding-top: 0;
}
.list-comp tfoot td {
    padding: 0.5em 0 0.5em 1em;
    font-size: 0.8em;
}
tfoot li {
	/* margin-right: 1em; */
	padding-right: 1em;
}
tfoot li:last-child {
	
	margin-left: auto;
}

.aa{
    text-align: right;
}
td.aa{
    font-size:0.85em;
    font-style: italic;
}
.cnt{
    text-align: center;
}



td a{
    text-decoration: none;
    color:var(--dec);
}
td a:hover{
    text-decoration:underline;
}

.toggle{
    cursor:pointer;
    color:var(--dec);
}
.toggle:hover{
    text-decoration: underline;
}
.mail-off{
    color:#800000;
    min-width: 32px;
    display: inline-block;
}
.mail-on{
    color:#008000;
    min-width: 32px;
    display: inline-block;
}

.rj{
    text-align: right;
    padding-right: 1em;
}

td.search-col{
    padding: 0;
}

.lbl-switch-sm{
    position:inherit;
    display: inline;
    margin-left:0.1em;
    cursor: pointer;
    clear:left;
}

.warn{
    color: orange;
}
