/**{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}*/
.schdl-day-container{
    position: relative;
    height:100%;
    border-left:1px solid #808080;
    margin:0 auto;
    padding:0;
    width:100%;
    max-width: 1500px;
    
}
.schdl-day-flexrow{
    display: flex;
    
}

.head-hours{
    position:sticky; 
    top:15.55rem;/*9.05rem;*/
    z-index: 3;
    background-color: #e0e0e0;
    overflow: hidden;
    border-top: 1px solid #808080;
}
ul.schdl-day-control{
    position: sticky;
    top:4.3em;
    z-index: 4;
    list-style: none;
    margin:0;
    display:flex;
    font-size: 1rem;
    padding: 1rem 0;
    background-color: var(--bg);
    align-items: center;
    height:4rem;
}

ul.schdl-day-control li {
    padding: 0 0.2rem;
}

.schdl-day-flexcol-container{
    display: flex;
    flex-direction: column;
    /*border:1px solid #0000ff;*/
}

.flexrooms{
    width: 20%; /*var(--hrsw);*/
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: var(--hrsw);
    /*border: 1px solid #808080;*/
    align-content: center;
}

.flexrooms > div{
    width:100%;
    height:3rem;
    border-bottom: 1px solid #808080;
    
    border-right: 1px solid #808080;
    box-sizing: border-box;
    text-align: right;
    padding: 1rem 0.1rem;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flexrooms .nb{
    
    border-bottom: 1px solid #808080;
    border-left: none;
    border-right: 1px solid #808080;
}
.rooms-hours{
    position: relative;
    z-index:0;
    box-sizing: border-box;
    width:80%;/*var(--cw);*/
    /*min-width:var(--cw);*/

    /*border-left:1px solid #808080;*/
}
.selected-period{
    position: absolute;
    z-index:-1;
    box-sizing: border-box;
    top:0;
    background-color: #da1884;
    height: 100%;
    left:9.0909%;
    width: calc(9.0909% * 2);
    opacity: 0.9;
}
.occ-part, .occ-tool, .occ-profile, .occ-wkhr{
    position: absolute;
    z-index:0;
    box-sizing: border-box;
    top:0;
    background-color: transparent;
    height: 100%;
    left:9.0909%;
    width: calc(9.0909% * 2);
    opacity: 0.8;
    cursor:not-allowed;
    color:#000;
    font-size: 0.75rem;
}
.occ-tool{
    background-color: #721768;/*#739014;/*#92bc1e;/*#aaad01;*/
}

.occ-profile{
    background-color: #aaad01;/*#739014;/*#92bc1e;/*#aaad01;*/
}

.occ-wkhr{
    background-color:#c2c4c4 ;
}

.flexroomshours-container{
    display: flex;
    width:100%;
    box-sizing: border-box;
    /*border-top:1px solid #808080;*/
    height:100%;
}

.flexroomshours{
    display:flex;
    flex-direction: column;
    justify-content: center;
    width:9.0909%;/*var(--dw);*/
    max-width: 9.0909%;/*var(--dw);*/
    box-sizing: border-box;
    border-bottom: 1px solid #808080;
    border-right: 1px solid #808080;
}
.flexroomshours-content{
    display:flex;
    flex-direction: column;
    width:9.0909%;/*var(--dw);*/
    max-width: 9.0909%;/*var(--dw);*/
    box-sizing: border-box;
    
}
.flexroomshours-content > div{
    width:100%;
    height:100%;
    box-sizing: border-box;
    border-bottom: 1px solid #808080;
    border-right: 1px solid #808080;

}

.appointment-day{
    position: absolute;
    display:flex;
    flex-direction: column;
    height:3rem;
    border-radius: 5%;
    top:0;
    z-index: 1;
    box-sizing: border-box;
    opacity: 0.6;
    background-color: #b7c9d3;
    border: 1px solid #6399ae;
    justify-content: center;
    overflow-x:hidden;
}  

.appointment-day > div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.8rem;
}




