

.tooltip{
    position:absolute;
    z-index: 10;
    opacity: 0.9;
    color:#000;
    background-color: whitesmoke;
    width:350px;
    height:0;
    transform: translate(2rem, -85%);
    animation-name: tooltip-anim;
    animation-duration: 0.5s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    overflow: hidden;
    box-shadow: 2px 2px 5px #acacac;
    box-sizing: border-box;
}

@keyframes tooltip-anim {
    from   {height: 0;}
    to {height: 250px;}
}

.tooltip h3{
    font-size: 1rem;
    margin:0 auto;
    color:#fff;
    background: var(--dec);
    padding: 0.25rem 0 0.25rem 2rem;
}

.tooltip .tp-container{
    display:flex;
    align-items: center;
    margin :1rem auto;
    padding: 0 0.5rem;
}


.tooltip .tp-icon{
    margin: 0 1rem 0 0;
    color:var(--dec);
}
 .tooltip ul.tp-list{
    list-style-type: none;
    margin: 0;
    padding: 0;
    width:100%;
 }
 .tooltip ul.tp-list>li{
    box-sizing: border-box;
    margin:0;
    padding:0.1rem 0 0.1rem 0.2rem;
    color:var(--dec);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }

 .tooltip span{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }

 .tp-result{
    font-size: 0.8rem;
    border-bottom:2px solid transparent;
    padding-left:2rem ;
   
 }

 .tooltip span.f-n{
    font-size: 0.7rem;
    white-space:normal;
    overflow:unset;
    text-overflow:unset;
    margin-right: 0.1rem;
 }

 .res-green, .res-red, .res-orange{
    position: relative;
    
 }

 .res-green::before, .res-red::before, .res-orange::before{
    position:absolute;
    content: '';
    top:0;
    left:0;
    height: 100%;
    width:0.9rem;
    border-radius: 50%;
 }

 .res-green::before{
    background-color:green;
 }
 .res-red::before{
    background-color:red;
 }
 .res-orange::before{
    background-color:orange;
 }

 
