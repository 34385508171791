 button {
	padding: 0.5em;
	border-radius: 5px;
	border-width: 1px;
	color: var(--dec);
	text-align: center;
	min-width: 2em;
}
button:disabled{
    background-color: var(--disabled);
}

.current-page{
    background-color: var(--dec);
    color:#fff;
}

