.rv-search{
    text-align: left;
    background-color:#e0e0e0;
    position: sticky;
    top:4.3rem;
    z-index: 4;
}
.rv-search-container{
    min-height:11.25rem;
}
.rv-top-search{
    display:flex; 
    align-items:center;
}

.rv-search-lbl{
    font-weight:normal;
     margin:unset;
     margin-left:0.5rem;
}

.rv-search-ul{
    display:flex;
    justify-content:flex-start;
    margin-top:0.2rem;
}

.icons-app{
    cursor: pointer;
}

.list-comp thead.rv-search-list-head{
    top:15.5rem;
    position: sticky;
    z-index: 0;

}

