.lbl-switch{
    position: relative;
    top:0.2em;
    display:inline-block;
    margin-left:1em;
    cursor: pointer;
}
.dis{
    color:#800000;
}
.qr-container{
    min-height:10em;
}
.qr-url{
    font-size:0.9em;
    margin-top:1em;
}

.bottom-place{
    margin-top:1.5em;
}

.update-btn{
   align-self: end;
    background-color:var(--dec);
    color:#fff;
    margin:0 0 0.5em 0.7em;
    font-size:1.5em;
    padding:0.35em 4em;
    width:320px;
    max-width: 320px;
   
}
h2, h4{
    margin:0.1em 0 0 2.01rem;
    padding: 0;
   
}
h2{
    font-size: 1.3em;
}
h4{
    color:var(--dec);
    margin-bottom: -0.5em;
}

.work-hours{
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(7, 1fr);
    gap: 1rem 1rem;
}