
.app-container{
    margin: 0 auto;
    max-width: 1600px;
}


nav{
    display: flex;
    font-size: 1.2rem;
    padding: 1rem 0;
    background-color:var(--menu-bg);
    position:sticky;
    top:0;
    z-index: 2;
}
/*
nav > span{
    color:#fff;
}
*/
nav > span.timeout{
    color:#d8000c;
}
nav > a{
    padding:0 1rem;
    text-decoration: none;
    color:#fff;
}
nav > a:hover{
    text-decoration: underline;
    
}

nav >.logout{
    margin-left: auto;
}
nav > span.city{
    color:#c1a4fc;
    margin-left: auto;  
}
/*  ------- checkbox ------- */
.lbl-chk{
    position:relative;
    display:inline-block;
    cursor: pointer;
    padding:5px 0 5px 35px;

    user-select: none;
    -webkit-user-select:none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin-top: 0;
    font-weight: normal;
}
.single{
    margin-top: 1em;
    font-weight: bold;
}

.lbl-chk input[type=checkbox]{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width:0;
}

.checkmark{
    position: absolute;
    top:0;
    left:0;
    height: 25px;
    width:25px;
    border:1px solid var(--bd-color);
    border-radius: 0.2em ;
}
.lbl-chk:hover input[type=checkbox] ~ .checkmark{
    background-color: #eee;
}
.lbl-chk input[type=checkbox]:checked ~ .checkmark{
    background-color:var(--dec); /* ------------------------------------- */
}

.checkmark::after{
    content: "";
    position:absolute;
    display:none;
}

.lbl-chk input[type=checkbox]:checked ~ .checkmark::after{
    display: block;
}

.lbl-chk .checkmark::after{
    left:9px;
    top:5px;
    width:5px;
    height:10px;
    border:solid #fff;
    border-width:0 3px 3px 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
}




/*  ------- radio ------- */

input[type=radio]{
    position: relative;
    display:inline-block;
    border:2px solid var(--bd-color);
    color:var(--txt-color);
    top:5px;
    height:25px;
    width:25px;
    cursor:pointer;
    margin-right: 1em;
    outline:none;
    border-radius: 50%;
    padding: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance:none;
}
input[type=radio]:checked{
    border-color:var(--dec);
}

input[type=radio]:checked::before{
    content: '';
    position:absolute;
    margin: 0;
    padding: 0;
    top:4px;
    left:4px;
    width:14px;
    height:14px;
    background-color:var(--dec);
    border-radius: 50%;
}
input[type=radio]:disabled{
    background-color: var(--bg);
}

/* --------------- slider -------------------*/
.slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance:none;
    width: 50%;
    height: 25px;
    background: #dfdfdf;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: var(--dec);
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: var(--dec);
    cursor: pointer;
  }

  

/* ------------ switch --------------------------------*/
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 26px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .sw-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .sw-slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .sw-slider {
    background-color: var(--dec);
  }
  
  input:focus + .sw-slider {
    box-shadow: 0 0 1px var(--dec);
  }
  input:checked + .sw-slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
  }
  
  /* Rounded sliders */
  .sw-slider.round {
    border-radius: 34px;
  }
  
  .sw-slider.round:before {
    border-radius: 50%;
  }

/* -------------------------------------------------- */
.slider-txt{
    display: inline-block;
    padding: 0.1em 0.5em;
    position: relative;
    top:-0.4em;
    font-size: 1.2em;
    color:var(--dec);
}
.weak {
    color:#d8000c;
    background-color: #ffbaba;;
}
.message-box{
    position: fixed;
    text-align: center;
    border: 2px solid #059;
    color:#059;
    border-radius: 5px;
    width:80%;
    padding:0.75em;
    font-size:1.2em;
    z-index: 10;
    top:calc(50vh - 3em);
    left:10%;
    background-color: #bef;
    font-family: inherit;
    word-break: break-all;

}
.color-180, .color-200,.color-300, .color-400, .color-500, .color-600, .color-700, .color-800, .color-850,
.color-950,  .color-1000, .color-1100, .color-1200, .color-1300, .color-1400, .color-1500, .color-1600, .color-1700, .color-1800{
    color:#fff;
    font-weight: bold;
    font-size: 0.8em;
    align-self:center;
    padding:0.3em 0.5em 0.3em 0.5em;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
}
.color-180{
    border-color :#ff0000;
    background-color: #a00000
}

.color-200 {border-color :#ff0000;}
.color-300 {border-color:#ff2000;}
.color-400 {border-color:#ff4000;}
.color-500 {border-color:#ff6000;}
.color-600 {border-color:#ff8000;}
.color-700 {border-color:#ffa000;}
.color-800 {border-color:#ffc000;}
.color-850 {border-color:#ffe000;}
.color-950 {border-color:#ffff00;}
.color-1000 {border-color:#f0ff00;}
.color-1100{border-color: #e0ff00;}
.color-1200 {border-color:#c0ff00;}
.color-1300 {border-color:#a0ff00;}
.color-1400 {border-color:#80ff00; }
.color-1500 {border-color:#60ff00; }
.color-1600 {border-color:#40ff00;}
.color-1700 {border-color:#20ff00;}
.color-1800 {border-color:#00ff00;}

.icon-remain{
    font-size: 1em;
    color:transparent;
    margin-left: 1em;
    font-weight: normal;
}
.icon-remain:hover{
    cursor: pointer;
    opacity: 0.75;
}
.c-180{color:#fff; background-color:#a00000;}
.c-200{color:#ff0000}
.c-300{color:#ff2000}
.c-400{color:#ff4000;}
.c-500{color:#ff6000;}
.c-600{color:#ff8000;} 
.c-700{color:#ffa000;}
.c-800{color:#ffc000;}
.c-850{color:#ffe000;}
.c-950{color:#ffff00;} 
.c-1000{color:#f0ff00;}
.c-1100{color:#e0ff00;}
.c-1200{color:#c0ff00;}
.c-1300{color:#a0ff00;}
.c-1400{color:#80ff00;}
.c-1500{color:#60ff00;}
.c-1600{color:#40ff00;}
.c-1700{color:#20ff00;}
.c-1800{color:#00ff00;}



.remain-des{
    color:var(--bd-color);
    padding-right: 0.4em;
    font-size: 0.8em;
    align-self:center;
}

.blink_me {
    animation: blinker 1.25s linear infinite;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0.4;
    }
  }

.suc{
    background-color: #dff2bf;
    color:#270;
    border-color:#270;
}
.err{
    background-color: #ffbaba;
    color:#d8000c;
    border-color:  #d8000c;
}
.info{
    color:#059;
    background-color: #bef;
    border-color:#059;
}

select.change-city{
    padding: 0.5em;
    border-radius: 0.2em;
    border: 1px solid #acacac;
    color:#1b1820;
    font-size: 1.2em;
    width:300px;
}
/*
select.change-city:focus{
    outline: 3px solid var(--bd-color);
}*/

.btn-link {
    color:var(--dec);
    padding:0.5em;
    border:1px solid var(--dec);
    margin-top:2em;
    display:inline-block;
    text-decoration: none;
}
.btn-link:hover{
    text-decoration:underline ;
}
.btn-link:active{
    opacity: 0.7;
}

.opt-label {
    margin-right:1rem;
    color:var(--dec);
    background:#e5e5ea;
    padding: 0.2rem;
    border-radius:5px;
    font-size:0.8rem;
    font-weight:bold;
    position:relative;
}
.opt-label::before{
    content: 'Α.Φ.:';
    font-weight: normal;
    color:#000;
    margin-right: 0.2rem;
    font-size:0.7rem;
}

