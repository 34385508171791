
:root {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    --bd-color:#acacac;
    --txt-color:#1b1820; 
  
   
    /*--dec: #505080;
    --dec:#8040ff; --------------------*/
    --dec: slateblue;
    --title-bg:#808080;
    --action-dec:#e0e0e0;
    --menu-bg:#1a004e;
   /*--menu-bg:#3D5262;*/
   --disabled:#a9a9a9;
   --bg:#f0f0f0;
}
html {
    height: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
body {
    padding:0 2vw;
    height: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12pt;
    background-color: var(--bg);
    color:var(--menu-bg);
    margin:0;

}
