.comp-grid-container{
    display:grid;
    grid-template: auto auto / repeat(2, auto); 
    margin-bottom: 0.5em;
    width:90%;
    align-content: center;
    /*border: 1px solid var(--action-dec);*/
    border-radius: 5px;
    
}
.grid-content-list{
    grid-column: 1/-1;
    
}
.list-item{
    /*margin-left: 1em;*/
    padding: 0.2em 0;
    
}

.list-flex{
    display: flex;
    column-gap: 1em;
}
h5{ 
    /*margin-left: 1em;*/
    /*padding: 1em 0;*/
    align-self: stretch;
    /*background-color: var(--action-dec);*/
    grid-column: 1/2;
    font-weight: normal;
    font-style: italic;
    align-self: center;
    color:dimgray;
    
}



.grid-comp-tool{
    grid-column:2/-1;
    /*background-color: var(--action-dec);*/
    /*padding: 0.5em 0;*/
    
}

.grid-icons{
    color:var(--dec);
    margin: 0 0.25em;
    cursor: pointer;
    font-size: 1.5em;
    float: right;
}
.grid-icons:hover{
    opacity: 0.5;
}
/*
.Modal-module_overlay__21YAk{
    overflow: auto;
}
*/
.myclass{
    max-width:600px;   
}
.diagnclass{
    overflow: visible;
    --st-dialog-maxWidth: 80vw;
    --st-dialog-maxContentHeight: 95vh;
}
/*
.diagn{
    
    --st-dialog-maxContentHeight: 100vh;
    overflow:auto;
    height: 90%;
}
.diagn .Modal-module_modalBody__3_RoW{
    overflow-y:visible;
    
}
*/
/*
.diagnclass .Modal-module_modalBody__3_RoW{

   
    
}
*/

.smallwin{
    min-width:unset; 
    
}

.icons{
    color:var(--dec);
    margin: 0 0.25em;
    cursor: pointer;
    font-size: 1.5em;
}
.icons:hover{
    opacity: 0.5;
}

.add-new-item{
    margin-left:0;
}
.family-tbl{
    width:90%;
    max-width: 92%;
}

.family-tbl th{
    background-color:#f0f0f0;
    padding:0.5em 0.2em;
}
.family-tbl tbody td {
    border-bottom: 1px solid #acacac55;
    padding: 0.5em 0.2em;
}
.family-tbl tfoot td {
    border-bottom:unset;
    padding: 0.5em 0.2em;
}

td.family-name{
    max-width:200px;
}
.tbl-act{
    display: flex;
    justify-content: flex-end;
    
}
.txt-right{
    text-align: right;
}





.comp-container{
    display:flex;
    margin-right: 3em;
    gap:0.5em;
    padding: 0.25em 0 ;
    margin-bottom:0.25em;
}
.comp-container:hover{
    background-color: #f8f8f8;
}

.comp-field{
    flex:1;
    margin-top:1.5rem;
}


.comp-date{
    font-weight: bold;
    align-self: flex-start;
    margin-top: 1.5rem;
}
.comp-tool{
    align-self: flex-start;
}

