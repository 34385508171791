

.request{
    display:grid;
    grid-template: repeat(4, minmax(2em ,auto)) / repeat(12, 1fr);
    /*background-color:#f0f0f0;*/
    border:1px solid #acacac70;
   
    margin: 0.5em auto;
}


.req-label{
    grid-column: 1 / span 2;
    font-weight: bold;
    font-size:0.8em;
    padding: 1em;
    
}
.req-date{
    grid-column: 1/ 3;
    grid-row: 1 / span 2;
    color:var(--dec);
    font-weight: bold;
    font-size: 1.2em;
    padding: 0.5em;
    background-color: #fff;
   

}
.req-options{
    grid-column: 3 / 7 ;
    grid-row: 1 / span 2;
    background-color: #fff;
    padding: 0.5em;
}
.req-options ul{
    list-style-type: disc;
    padding-left: 0.5em;
}
.req-options ul li{
    line-height: 1.4em;
}
.req-des{
    grid-column: 7 / 12;
    grid-row: 1 / span 2;
    padding: 0.5em 1em;
    font-style: italic;
    font-size: 0.9em;
    background-color: #fff;
}
.req-answer, .req-outcome {
    grid-column: 3/12;
    padding: 0.7em 1em;
    background-color: #f0f0f0;
}
.line-outcome{
    background-color: #8040ff14;
}
.line-answer{
    background-color: #f0f0f0;;
}

.req-act{
    grid-column: 12/-1;
    display: flex;
    align-content: space-between;
    padding: 0.5em;
}


