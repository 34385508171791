
.glass, .glass-confirm{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom: 0;
    width:100%;
    height: 100%;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
    z-index: 1;
    display: grid;
    justify-content: center;
    overflow: auto;
}
.glass-confirm{
    place-content: center;
}
.glass-content{
    background-color: #fff;
    padding: 2em;
    align-self: start;
}

.return-btn{
    background-color: var(--dec);
    border-width: 1px;
    border-radius: 5px;
    font-size:1.2em;
    color:#fff;
    padding: 0.5em 2em;
    margin-top:0.75em;
}
.return-btn:hover{
    opacity: 0.75;
    cursor: pointer;
}

.confirm{
    border: 3px solid var(--dec);
    border-radius: 5px;
}

.glass-content label{
    margin:0.25em 0;
    cursor: pointer;
}
.yes-no-toolbar{
    display:flex;
    justify-content: space-between;
}
.yes-no-btn{
    padding:0.5em 1.5em;
    border-radius: 10px;
    border-width: 1px;
    color:var(--dec);
    font-size: 1.2em;
}

.modalgen{
    padding-left: 1.2em;
    padding-right: 1em;
   /* min-height: 250px;*/
  
}
.modalfamily{
    --st-modal-minWidth:50vw;

}
.modalcontent{
    overflow-y: auto;
    
}
.modalfooter{
    position: sticky;
    bottom:0;
    display: flex;
    margin-top:1em;
    background-color: #e0e0e0;
    justify-content: flex-end;
    padding: 0 0.5em 0.5em 0 ;
}
.modalfooter > *{
    margin-left: 1em;
}

.modal-label{margin: 0;cursor:pointer;font-weight: normal;max-width: unset;}

.select{
    height:100%;
}

/*.select .css-26l3qy-menu{
    position:unset;
}*/
.sm-select{
    width:320px;
}

ul.menu{
    position: sticky;
    top:0;
    display:flex;
    
    background-color: var(--menu-bg);
    justify-content: space-evenly;
    width:100%;
    z-index: 1;
}
ul.menu li{
    padding: 1em 0.5em;
    color:white;
    cursor: pointer;
    border: 2px solid transparent;
}
ul.menu li:hover{
    opacity: 0.75;
}

.menu .active{
    border-left: 2px solid var(--bd-color);
    border-right: 2px solid var(--bd-color);
}

.request-modal{
    --st-modal-minWidth:80vw;
    --st-modal-backgroundColor:#f0f0f0;
}

.profile-modal{
    --st-modal-minWidth:95vw;
}
textarea{
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
    color:dimgrey;
    font-size: 1em;
    resize: none;
    width: 95%;
    height:50%;
    background-color:transparent;
    line-height: 1.5em;
}

.text-info{
    font-size: 0.8em;
    color:dimgrey;
}

.orange{
    color: orange;
}
.red{
    color:#800000;
}